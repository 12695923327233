import React, { Component } from 'react';
import { setLoginStatus } from '../actions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Login from "../components/Login";
import Logout from "../components/Logout";
import Status from "../components/Status";
import Census from '../components/Census';
import Reports from '../components/Reports';
import Map from '../components/map';
import FacilityAlertSnippets from '../components/FacilityAlertSnippets';
import PageNotFound from '../components/PageNotFound';
import CallList from '../components/CallList';
import 'bootstrap/dist/css/bootstrap.min.css';
import EPTP from '../components/eptp';
import { store } from '../store.js';
import { Switch, BrowserRouter as Router, Route } from 'react-router-dom';
import { setStaleStatuses, setLogOutStatus, setEPTPStatus } from '../actions';
import { FRONTEND_URL, BACKEND_URL } from '../util/Constants'

/*This is the main data providing container for the app.
  the AppContainer component is connected to the redux store and provides state to it's
 children presentational components. The AppContainer component is also responsible
 for the various url routes. Any additional urls that need to be added to RTAS in the
 future must be added to this component.*/
class AppContainer extends Component {

  /* Component constructor and state*/
  constructor(props) {
    super(props);
    this.state = {
      loginStatus: this.props.loginStatus,
      statusList: this.props.statusList,
      censusList: this.props.censusList,
      contactList: this.props.contactList,
      seconds: 0,
      endpoint: BACKEND_URL + '/api/eptp/',
    };
  }
  /*------Data Fetching Methods----------------*/
  /*
  This function is responsible for fetching any EPTP active statuses and posting the response to the
  redux store. */
  monitorEPTP = () => {
    if (!this.props.loginStatus) { return null }
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    headers.append('Origin', FRONTEND_URL + '/');
    //set the fetch configurations
    const conf = {
      method: 'get',
      headers: headers,
    };
    //fetch the resource from the backend and set the status to the response data
    return fetch(this.state.endpoint, conf).then(response => {
      if (response.ok) {
        return response.json()
      }
      throw new Error("eptp load failure");
    }).then(jsonData => {
      if (jsonData.length > 0) {
        store.dispatch(setEPTPStatus(jsonData[0]))
      }
      else {
        store.dispatch(setEPTPStatus({ initiated: false, id: 0, triggered: false }))
      }
    }).catch((error) => { })
  }

  isStale = (updated, ed) => {
    // format of djano time "2019-03-27T19:08:53.794642-04:00"
    var months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    var updatedFormat = months[parseInt(updated.substring(5, 7)) - 1] + " " + updated.substring(8, 10) + ", " + updated.substring(0, 4) + " " + updated.substring(11, 19) + " UTC" + updated.substring(26, 32)
    //console.log(updatedFormat)
    var date1 = new Date(updatedFormat);
    //console.log(date1)
    var today = new Date();
    var hours = Math.abs(today - date1) / 36e5;
    return hours > 8;
  }

  /*------LIFE CYCLE METHODS ---------*/


  componentWillMount() {
    this.monitorEPTP()
    //automatically logout after eight hours (8hrs * 60(mins/hr) * 60(seconds/min) *1000)
    this.eptpInterval = setInterval(() => { this.monitorEPTP() }, 30000)
    this.interval = setInterval(() => { store.dispatch(setLogOutStatus(false)) }, (8 * 60 * 60 * 1000));
    //check for a stale status every 60 seconds
    this.staleStatusInterval = setInterval(() => { this.checkForStaleStatus() }, 60000)
  }
  componentDidMount() {

  }
  checkForStaleStatus = () => {
    var usersStatusList = this.props.statusList.filter((status) => { return (status.ED.name === this.props.userED) })
    //console.log(eds[0].updateDateTime)
    var statuses = usersStatusList.map((status) => { return { ED: status.ED.name, lastUpdate: status.updateDateTime } })
    //console.log("Checked For Stale Status")
    //console.log(statuses)
    store.dispatch(setStaleStatuses(statuses.filter(status => { return this.isStale(status.lastUpdate, status.ED) })))
  }
  componentWillUnmount() {
    clearInterval(this.interval);
    clearInterval(this.eptpInterval);
    clearInterval(this.staleStatusInterval)
  }
  /*
  Renders a router with a series of routes. Each route renders a particular component and passes necessary props
  */
  render() {
    return (
      <Router>
        <div>
          <Switch>
            <Route path='/login' render={(props) => <Login username={this.props.username} auth={this.props.loginStatus} eptpStatus={this.props.EPTPStatus} />} />
            <Route exact path='/' render={(props) => <Status username={this.props.username} {...this.props} edName={this.props.userED} auth={this.props.loginStatus} userType={this.props.userType} status={this.props.statusList} eptpStatus={this.props.EPTPStatus} />} />
            <Route path='/census' render={(props) => (<Census {...this.props} username={this.props.username} auth={this.props.loginStatus} census={this.props.censusList} userType={this.props.userType} eptpStatus={this.props.EPTPStatus} />)} />
            <Route path='/notificationlist' render={(props) => <CallList {...this.props} username={this.props.username} auth={this.props.loginStatus} userType={this.props.userType} contacts={this.props.contactList} eptpStatus={this.props.EPTPStatus} edName={this.props.userED} />} systemName={this.props.userSystem} eds={this.props.eds}/>
            <Route path='/reports' render={(props) => <Reports username={this.props.username} {...this.props} auth={this.props.loginStatus} userType={this.props.userType} eptpStatus={this.props.EPTPStatus} />} />
            <Route path='/eptp' render={(props) => (<EPTP confirmationVisibility={this.props.eptpConfirmationVisibility} {...this.props} username={this.props.username} auth={this.props.loginStatus} census={this.props.censusList} userType={this.props.userType} eptpStatus={this.props.EPTPStatus} />)} />
            <Route path='/map' render={(props) => (<Map {...this.props} username={this.props.username} auth={this.props.loginStatus} census={this.props.censusList} userType={this.props.userType} eptpStatus={this.props.EPTPStatus} />)} />
            <Route path='/facilityAlertSnippets' render={(props) => (<FacilityAlertSnippets {...this.props} username={this.props.username} auth={this.props.loginStatus} census={this.props.censusList} userType={this.props.userType} eptpStatus={this.props.EPTPStatus} />)} />
            <Route path='/logout' render={(props) => <Logout {...this.props} auth={this.props.loginStatus} />} />
            <Route render={(props) => <PageNotFound {...this.props} eptpStatus={this.props.EPTPStatus} auth={this.props.loginStatus} userType={this.props.userType} />} />
          </Switch>
        </div>
      </Router>
    );
  }
}
/*------------REDUX STATE -> COMPONENT PROPS --------------- */

function mapStateToProps(state, props) {
  return { loginStatus: state.loginStatus, statusList: state.statusList, censusList: state.censusList, contactList: state.contactList, eptpStatus: state.EPTP_Is_Active, log: state.dailyLog, EPTPStatus: state.EPTPStatus, userType: state.userType, userED: state.userED, userSystem: state.userSystem, username: state.username, eptpConfirmationVisibility: state.eptpConfirmationVisibility, staleStatuses: state.staleStatuses, messages: state.messages, eds: state.eds }

}
function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(setLoginStatus, dispatch) }
}
export default connect(mapStateToProps, mapDispatchToProps)(AppContainer);
