import React, { Component } from 'react';
import { statusCodes, codeStylesDark } from '../util/Enums'
import { TABLE_CLASS, FRONTEND_URL, BACKEND_URL } from '../util/Constants'
import { store } from '../store';
import { setMessages, setStaleStatuses, setStatus } from '../actions/index.js';
import PageWrapper from '../wrappers/Page';
import FormatedDateTime from './FormatDateTime.js'
import Comment from './Comment.js';

/*
This presentational component is for the entire Status Page. Anything that needs to display on the status page must be added here.
*/
class Status extends Component {

  constructor(props) {
    super(props);
    this.state = {
      status: [], //initialize to empty array
      auth: this.props.auth, //receive the auth status from the AppContainer
      endpoint: BACKEND_URL + '/api/status/', //url for accessing the backend status api
      updated: ""
    }
  }

  /*------------------DATA FETCHING METHODS------------------------------*/
  formatDate = (date) => {
    var curYear = date.getFullYear(),
        curMonth = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1,
        curDay = date.getDate() < 10 ? "0" + date.getDate() : date.getDate(),
        curHour = date.getHours() < 10 ? "0" + date.getHours() : date.getHours(),
        curMinute = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    return curMonth + "/" + curDay + "/" + curYear + " " + curHour + ":" + curMinute;
  }
  updateTime = () => {
    let today = this.formatDate(new Date());
    this.setState({ updated: today })
  }
  handleMount = () => {
    //create the header and append attributes to the header
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    headers.append('Origin', FRONTEND_URL + '/status');
    //set the fetch configurations
    const conf = {
      method: 'get',
      headers: headers,
    };
    //fetch the resource from the backend and set the status to the response data
    return fetch(this.state.endpoint, conf).then(response => {
      if (response.ok) {
        return response.json()
      }
      throw new Error("status table load failure");
    }).then(jsonData => {
      store.dispatch(setStatus(jsonData))
      this.checkForStaleStatus(jsonData);
      this.updateTime();
      this.setNotificationTimer();
    }).catch((error) => { })

  }
  fetchMessages = () => {
    //create the header and append attributes to the header
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    headers.append('Origin', FRONTEND_URL + '/status');
    //set the fetch configurations
    const conf = {
      method: 'get',
      headers: headers,
    };
    //fetch the resource from the backend and set the status to the response data
    return fetch(BACKEND_URL + "/api/messages/", conf).then(response => {
      if (response.ok) {
        return response.json()
      }
      throw new Error("status table load failure");
    }).then(jsonData => {
      store.dispatch(setMessages(jsonData))
    }).catch((error) => { })
  }

  /*----------------LIFE CYCLE METHODS ---------------------*/
  /*Called before the component is loaded. If the redux store updates, then new props will be passed to this component
  and it will re-render*/
  componentWillMount() {
    this.handleMount();
    this.fetchMessages();
    //rerender the component every minute (1minute * 60seconds * 1000)
    this.interval = setInterval(() => this.handleMount(), 60000);
    this.messageinterval = setInterval(() => this.fetchMessages(), 60000);
  }
  componentDidUpdate(prevProps) {
    if (prevProps.status !== this.props.status) {
      this.checkForStaleStatus(this.props.status)
    }
  }

  checkForStaleStatus = (statusList) => {
    var usersStatusList = statusList.filter((status) => { return (status.ED.name === this.props.userED) })
    var statuses = usersStatusList.map((status) => { return { ED: status.ED.name, lastUpdate: status.updateDateTime } })
    let staleStatuses = statuses.filter(status => this.isStale(status.lastUpdate, status.ED))
    store.dispatch(setStaleStatuses(staleStatuses))
  }

  timeoutHandle = 0;
  setNotificationTimer() {
    if (window.Notification.permission !== 'granted') return;
    let status = this.props.status.filter(s => s.ED.name == this.props.userED)[0];
    if (!status) return;
    // Compute the time until the status update becomes stale. Set an timeout for the millisecoonds between now and that time
    let date = new Date(status.updateDateTime);
    let overdueMs = date.getTime() + 1000 * 60 * 60 * 6;
    let nowMs = new Date().getTime();
    let msUntilDue = overdueMs - nowMs;
    navigator.serviceWorker.register('sw.js');
    window.clearTimeout(this.timeoutHandle);
    if (msUntilDue > 0) {
        this.timeoutHandle = window.setTimeout(() => this.showOverdueNotification(status), msUntilDue);
    } else {
        this.showOverdueNotification(status);
    }
  }
  showOverdueNotification(lastStatus) {
    let formatDate = this.formatDate;
    navigator.serviceWorker.ready.then(function (registration) {
        registration.showNotification('RTAS Status Update is Overdue', { body: "The last status update for " + lastStatus.ED.name + " was given at " + formatDate(new Date(lastStatus.updateDateTime)) });
/*
        new window.Notification('RTAS Status Update is Overdue', {
            body: "The last status update for " + lastStatus.ED.name + " was given at " + this.formatDate(new Date(lastStatus.updateDateTime)),
        });
*/
    });
  }

  isStale = (updated, ed) => {
    var date1 = new Date(updated);
    var today = new Date();
    var hours = Math.abs(today - date1) / 36e5;
    return hours > 6;
  }

  //After the component unmounts, this is called to clear the time interval
  componentWillUnmount() {
    clearInterval(this.interval);
    clearInterval(this.messageinterval);
  }

  //If the user is not authenticated, render a redirect to the login page, otherwise display the status table.
  render() {
    return (
      <div id="pagestart">
        <div className="row">
          <Message {...this.props} />
          <div className="container-fluid small-font mt-4">
              <NotificationManagement  {...this.props} />
          </div>
          <div className="container-fluid small-font">
                  <div className="mt-4 text-center">
                        <p>
                            The emergency patient transport plan (EPTP) is activated when three or more adult Franklin County hospitals declare a divert status to EMS.
                        </p>
                        <p>
                            Visit <a target="_blank" href="https://www.cotshealth.org/emergencyservices">https://www.cotshealth.org/emergencyservices</a> for the current EPTP plan.
                        </p>
                  </div>
                  <div className="mt-2">{"Last Updated at: " + this.state.updated} </div>
          </div>
          <div className="col-lg mt-4">
            <EDStatusTable table_id="StatusTableHospital" ed_type="Hospital ED" status={this.props.status.filter((location) => location.ED.facility === 1)} />
          </div>
          <div className="col-lg mt-4">
            <EDStatusTable table_id="StatusTableFreeStanding" ed_type="Free Standing ED" status={this.props.status.filter((location) => location.ED.facility === 2)} />
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-3"></div>
          <div className="col-lg">
            <EDStatusTable table_id="StatusTableOutOfCounty" ed_type="Out of County ED" status={this.props.status.filter((location) => location.ED.facility === 3)} />
          </div>
          <div className="col-3"></div>
        </div>
        <hr />
        <div className="row">
          <div className="col-4"></div>
          <div className="col-lg">
            <StatusKey />
          </div>
          <div className="col-4"></div>
        </div>
      </div>

    );
  }
}
/* This component renders the html for the status table and header.
Individual rows are added as chidren components.
*/
export class EDStatusTable extends Component {
  render() {
    return (
      <table id={this.props.table_id} className={TABLE_CLASS}>
        <thead className="cotsBlue">
          <tr className="cotsBlue">
            <th style={{ width: '47.5%' }} scope="col">{this.props.ed_type}</th>
            <th style={{ width: '20%' }} scope="col">Status</th>
            <th style={{ width: '32.5%' }} scope="col">Updated</th>
          </tr>
        </thead>
        <tbody>
          {this.props.status.map((location) => { return (<StatusTableEntry key={location.ED.name} location={location} />) })}
        </tbody>
      </table>
    )
  }
}
/*This component representes a individual row in the status table*/
export class StatusTableEntry extends Component {
  isStale = (updated) => {
    var date1 = new Date(updated);
    var today = new Date();
    var hours = Math.abs(today - date1) / 36e5;
    return hours > 6;
  }
  render() {
    let facilityAlerts = this.props.location.facilityAlert.filter(fa => fa.description !== 'None');
    //{facilityAlerts.length > 0 ? <span style={{ "paddingTop": "2px", "paddingBottom": "2px", "marginLeft": "4px" }} className="badge badge-warning" title={"Facility Alert(s) are active for " + this.props.location.ED.name}>!</span> : null}
    //{this.props.location.edComment.length > 0 ? <span style={{ "paddingTop": "2px", "paddingBottom": "2px", "marginLeft": "4px" }} className="badge badge-danger pointer">+</span> : null}
    return (
      <>
      <tr key={this.props.location.ED.name}>
        <td className="hname" >
          <span className={""} data-tip data-event='click focus' data-for={'Comment' + this.props.location.ED.name}>
            {this.props.location.ED.name}
            {this.props.location.edComment.length > 0 ? <span className="ed-comment-icon">+</span> : null}
          </span>
          <Comment visible={this.props.location.edComment.length > 0 || this.props.location.diversionReasons.length > 0 || facilityAlerts.length} comment={this.props.location.edComment} reasons={this.props.location.diversionReasons} location={this.props.location} facilityAlert={facilityAlerts} />
        </td>

        <td className={codeStylesDark[this.props.location.statusLevel] + " "}>
          {statusCodes[this.props.location.statusLevel]}
        </td>

        <td className={this.isStale(this.props.location.updateDateTime) ? "StaleStatus" : ""}>
          <FormatedDateTime className="mb-0" visible={true} dt_str={this.props.location.updateDateTime} />
        </td>
      </tr>
      {facilityAlerts.filter(fa => fa.description !== 'None').map(fa =>
        (
            <tr><td colspan="3" className="facility-alert">{this.props.location.ED.name}: {fa.description.includes('See Comment') ? this.props.location.facilityAlertComment : fa.description}</td></tr>
        )
      )}
      </>
    );
  }
}
/*This component represents the static key for the status table. */
export class StatusKey extends Component {
  render() {
    return (
      <div className="">
        <table id="tablekey" className={TABLE_CLASS}>
          <thead className="thead-light">
            <tr>
              <th scope="col">Status Key</th>
              <th scope="col">Interpretation</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className={codeStylesDark[1]}>Mild</td>
              <td> </td>
            </tr>
            <tr>
              <td className={codeStylesDark[2]}>Moderate</td>
              <td>Possible Delays</td>
            </tr>
            <tr>
              <td className={codeStylesDark[3]}>High</td>
              <td>Expect Delays</td>
            </tr>
            <tr>
              <td className={codeStylesDark[3]}>High* </td>
              <td>Would still be on DIVERT if EPTP discontinued</td>
            </tr>
            <tr>
              <td className={"diversion-static"}>Divert</td>
              <td></td>
            </tr>
            <tr>
              <td className={"closed-static"}>Closed</td>
              <td></td>
            </tr>
            <tr>
              <td className={"facility-alert"}>Facility Alert</td>
              <td></td>
            </tr>
            <tr>
              <td ><span className="ed-comment-icon">+</span></td>
              <td>Click to view comment</td>
            </tr>
            <tr>
              <td className="StaleStatus"></td>
              <td>Status hasn't been updated in more than 6 hours</td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }
}
class NotificationManagement extends Component {
    componentWillMount() {
        this.lastNotifStatus = this.getNotifStatus();
        this.interval = setInterval(() => this.handleMount(), 1000);
    }
    componentWillUnmount() {
        clearInterval(this.interval);
    }
    getNotifStatus = () => window.Notification ? window.Notification.permission : null;
    handleMount = () => {
        let notifStatus = this.getNotifStatus();
        if (this.lastNotifStatus != notifStatus) {
            this.forceUpdate();
        }
        this.lastNotifStatus = this.notifStatus;
    }
    render() {
        if (!window.Notification) {
            return (<div>Notifications are unavailable in this browser.</div>);
        }
        if (window.Notification.permission !== 'granted') {
            return (<button onClick={(e) => Notification.requestPermission()}>
                Enable notifications
            </button>);
        } else {
            return (<div>Notifications are enabled</div>);
        }
    }
}
class Message extends Component {
  render() {
    return (<div style={{ display: this.props.messages.length > 0 ? "block" : "none" }} className="container-fluid mt-4 mb-3"><div className="d-flex justify-content-center align-items-center row "> <div className="col-6 px-3 py-3 border rounded">Messages: {this.props.messages.map((message, index) => { return <div key={index}>{message.message_text}</div> })}</div></div></div>);
  }
}
//page wrapper renders the skeleton for each page,
//the first set of parameters are (heading_value, component_id)
//the second set of parameters is just (Component) where Component is the component you wish to render in the page skelton
export default PageWrapper('ED Status', 'Status')(Status);
