import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import FormatedDateTime from './FormatDateTime.js'
import { NAV_CLASS, BACKEND_URL, FRONTEND_URL } from '../util/Constants';
import { userLinks } from './Links.js'
import { store } from '../store';
import { setStaleStatuses, setUserType, setEPTPStatus, setEDs } from '../actions/index.js';
class RtasNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      auth: this.props.auth,
      endpoint_usertype: BACKEND_URL + "/api/usertype/?username=",
      endpoint_eptp: BACKEND_URL + "/api/eptp/",
      endpoint_eds: BACKEND_URL + "/api/eds/",
    };
  }
  componentWillMount() {
    this.monitorEPTP();
    this.getUserType(this.props.username)
    this.getEDs();
  }

  isStale = (updated, ed) => {
    // format of djano time "2019-03-27T19:08:53.794642-04:00"
    var months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    var updatedFormat = months[parseInt(updated.substring(5, 7)) - 1] + " " + updated.substring(8, 10) + ", " + updated.substring(0, 4) + " " + updated.substring(11, 19) + " UTC" + updated.substring(26, 32)
    //console.log(updatedFormat)
    var date1 = new Date(updatedFormat);
    //console.log(date1)
    var today = new Date();
    var hours = Math.abs(today - date1) / 36e5;
    return hours > 8;
  }
  checkForStaleStatus = () => {
    var usersStatusList = this.props.statusList.filter((status) => { return ((status.ED.name === this.props.userED) || (status.ED.system.name === this.props.userSystem)) })
    //console.log(eds[0].updateDateTime)
    var statuses = usersStatusList.map((status) => { return { ED: status.ED.name, lastUpdate: status.updateDateTime } })
    //console.log(statuses)
    store.dispatch(setStaleStatuses(statuses.filter(status => { return this.isStale(status.lastUpdate, status.ED) })))
  }
  monitorEPTP = () => {
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    headers.append('Origin', FRONTEND_URL + '/');
    //set the fetch configurations
    const conf = {
      method: 'get',
      headers: headers,
    };
    //fetch the resource from the backend and set the status to the response data
    return fetch(this.state.endpoint_eptp, conf).then(response => {
      if (response.ok) {
        return response.json()
      }
      throw new Error("eptp load failure");
    }).then(jsonData => {
      if (jsonData.length > 0) {
        store.dispatch(setEPTPStatus(jsonData[0]))
      }
      else {
        store.dispatch(setEPTPStatus({ initiated: false, id: 0, triggered: false }))
      }
    }).catch((error) => { })
  }
  getUserType = (username) => {
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    //get access to the Login components state

    const conf = {
      credentails: 'omit',
      mode: 'cors',
      method: 'GET',
      headers: headers,
    };
    var url = this.state.endpoint_usertype + username;
    fetch(url, conf).then(response => {
      if (response.ok) {
        //console.log(response)
        return response.json();
      }
      throw new Error("Invalid username or password!");
    }).then(data => {
      if (data.length > 0) {
        store.dispatch(setUserType(data[0].user_type))
      }
    }).catch((error) => { })
  }
  getEDs = (username) => {
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    //get access to the Login components state

    const conf = {
      mode: 'cors',
      method: 'GET',
      headers: headers,
    };
    var url = this.state.endpoint_eds;
    fetch(url, conf).then(response => {
      if (response.ok) {
        //console.log(response)
        return response.json();
      }
      throw new Error("Invalid username or password!");
    }).then(data => {
      if (data.length > 0) {
        store.dispatch(setEDs(data))
      }
    }).catch((error) => { })
  }
  selectNavLinks = (userType, id) => {
    switch (userType) {
      case "Hospital User":
        return (<HospitalUserNavLinks backendLinks={userLinks.hospital_user.backend} frontendLinks={userLinks.hospital_user.frontend} {...this.props} />)
      case "Hospital Admin":
        return (<HospitalAdminNavLinks backendLinks={userLinks.hospital_admin.backend} frontendLinks={userLinks.hospital_admin.frontend}{...this.props} />)
      case "ED Admin":
        return (<HospitalAdminNavLinks backendLinks={userLinks.hospital_admin.backend} frontendLinks={userLinks.hospital_admin.frontend}{...this.props} />)
      case "COTS":
        return (<COTSNavLinks backendLinks={userLinks.cots.backend} frontendLinks={userLinks.cots.frontend} {...this.props} />)
      case "FAO":
        return (<FAONavLinks backendLinks={userLinks.fao.backend} frontendLinks={userLinks.fao.frontend} {...this.props} id={id} />)
      default:
        return (<EMSNavLinks backendLinks={userLinks.ems.backend} frontendLinks={userLinks.ems.frontend} {...this.props} />)
    }
  }
  render() {
    var id = "";
    if (this.props.eptpStatus.initiated) {
      id = this.props.eptpStatus.id;
    }
    return (
      <nav id={this.props.eptpStatus.initiated ? "RtasNavEPTP" : "RtasNav"} className={NAV_CLASS}>
        <NavLink className="navbar-brand" to="/"><img src={require('../COTS_LOGO.png')} height="50px" alt="" /></NavLink>
        <NavLink className="navbar-brand" to="/">RTAS</NavLink>
        <button className="navbar-toggler" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          {this.selectNavLinks(this.props.userType, id)}
          <FormatedDateTime className="mb-0" optionalPrefix="EPTP ACTIVATED: " dt_str={this.props.eptpStatus.activatedAt} visible={this.props.eptpStatus.initiated} />
          <a className="nav-link navbar-brand active" href="/management/admin/logout">Log Out</a>
        </div>
      </nav>)

  }
}
class FAONavLinks extends Component {
  render() {
    return (
      <ul id={"FAO"} className="navbar-nav mr-auto mt-2 mt-lg-0">
        <FrontendLink to="/" title="ED Status" />
        <DropDown mainTitle={"Divert Notification List"} frontendlinks={this.props.frontendLinks.callList} backendlinks={this.props.backendLinks.callList} />
        <FrontendLink to="/eptp" title="EPTP" />
        <FrontendLink to="/reports" title="Reports" />
      </ul>
    )
  }
}
class HospitalAdminNavLinks extends Component {
  render() {
    return (
      <ul id={"HospitalAdmin"} className="navbar-nav mr-auto mt-2 mt-lg-0">
        <DropDown mainTitle={"ED Status"} frontendlinks={this.props.frontendLinks.status} backendlinks={this.props.backendLinks.status} />
        <DropDown mainTitle={"Divert Notification List"} frontendlinks={this.props.frontendLinks.callList} backendlinks={this.props.backendLinks.callList} />
        <FrontendLink to="/reports" title="Reports" />
      </ul>
    )
  }
}
class HospitalUserNavLinks extends Component {
  render() {
    return (
      <ul id={"HospitalUser"} className="navbar-nav mr-auto mt-2 mt-lg-0">
        <DropDown mainTitle={"ED Status"} frontendlinks={this.props.frontendLinks.status} backendlinks={this.props.backendLinks.status} />
        <DropDown mainTitle={"Divert Notification List"} frontendlinks={this.props.frontendLinks.callList} backendlinks={this.props.backendLinks.callList} />

      </ul>
    )
  }
}
class EMSNavLinks extends Component {
  render() {
    return (
      <ul id={"EMS"} className="navbar-nav mr-auto mt-2 mt-lg-0">
        <DropDown mainTitle={"ED Status"} frontendlinks={this.props.frontendLinks.status} backendlinks={[]} />
      </ul>
    )
  }
}

class COTSNavLinks extends Component {
  render() {
    return (
      <ul id={"COTS"} className="navbar-nav mr-auto mt-2 mt-lg-0">
        <DropDown mainTitle={"ED Status"} backendlinks={[]} frontendlinks={this.props.frontendLinks.status} />
        <DropDown mainTitle={"Divert Notification List"} frontendlinks={this.props.frontendLinks.callList} backendlinks={this.props.backendLinks.callList} />
        <DropDown mainTitle={"Maintenance"} backendlinks={this.props.backendLinks.maintenance} frontendlinks={[]} />
        <FrontendLink to="/reports" title="Reports" />
        <FrontendLink to="/facilityAlertSnippets" title="Facility Alert Recordings" />
      </ul>
    )
  }
}
class DropDown extends Component {
  render() {
    return (
      <li className="nav-item dropdown active navbar-brand">
        <button id="navbarDropdownStatus" className="btn nav-link dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> {this.props.mainTitle}</button>
        <div className="dropdown-menu bg-light" aria-labelledby="navbarDropdownStatus">
          {this.props.frontendlinks.map((link) => { return (<NavLink key={link.id} className="nav-link dropdown-link pl-2" to={link.url}>{link.title}</NavLink>) })}
          {this.props.backendlinks.map((link) => { return (<a key={link.id} className="dropdown-item nav-link dropdown-link pl-2" href={link.url}>{link.title}</a>) })}
        </div>
      </li>
    )
  }
}
class FrontendLink extends Component {
  render() {
    return (
      <li className="nav-item active navbar-brand">
        <NavLink className="nav-link" to={this.props.to}>{this.props.title}</NavLink>
      </li>
    )
  }
}


export default RtasNav;
