//This file contains site Enumeration types and Site Constants
export const statusCodes = {
  1: "Mild",
  2: "Moderate",
  3: "High",
  4: "High*",
  5: "Divert",
  6: "Divert - Equipment Failure",
  7: "Closed",
  // Nedocs status levels
  // 1: "Not Busy",
  // 2: "Busy",
  // 3: "Extremely Busy",
  // 4: "Overcrowded",
  // 5: "Extremely Overcrowded",
  // 6: "Dangerously Overcrowded",
  // 7: "Diversion",
  // 8: "Closed",
}

export const facilityType = {
  1: "Hospital Emergency Departments",
  2: "Free Standing",
  3: "Out Of County",
}

export const codeStylesDark = {
  1: "bg-success",
  2: "bg-warning",
  3: "bg-danger  whiteText",
  4: "bg-danger  whiteText",
  5: "diversion",
  6: "diversion",
  7: "closed",
}
export const codeStylesLight = {
  1: "table-success",
  2: "table-warning",
  3: "table-danger",
  4: "diversion",
  5: "closed",
  6: "eptp",
}
export const facilityStylesDark = {
  1: "bg-danger ",
  2: "bg-primary ",
  3: "bg-secondary ",
}
export const facilityStylesLight = {
  1: "table-danger ",
  2: "table-primary ",
  3: "table-secondary ",
}
export const TABLE_MODE_CLASS = "table table-bordered shadow mt-0"
export const HEADER_MODE_CLASS = "col bg-light shadow-sm mb-0"
export const SUBHEADER_MODE_CLASS = "container-fluid shadow-sm cotsBlue"
export const NAV_MODE_CLASS = "navbar navbar-expand-lg navbar-dark cotsBlue sticky-top shadow "
export const EPTP_CLASS_MODE = " eptp-light "
export const BODY_CLASS = "container border mt-3 mb-3 rounded"
export const PAGE_MODE_CLASS = ""
export const TEXT_MODE_CLASS = " py-1"
export const BORDER_MODE_CLASS = "border rounded border-secondary "
export const BUTTON_MODE_CLASS = "btn btn-sm btn-light btn-block "
export const FOOTER_MODE_CLASS = "cotsBlue shadow-top footer "
