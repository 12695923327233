import React, { Component } from 'react';
import PageWrapper from '../wrappers/Page';
import { FRONTEND_URL, BACKEND_URL } from '../util/Constants'
import { store } from '../store';
import { setEPTPStatus } from '../actions/index.js'

import 'bootstrap/dist/js/bootstrap.bundle.min';
class EPTP extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: [], //initialize to empty array
      auth: this.props.auth, //receive the auth status from the AppContainer
      endpoint: this.props.eptpStatus.initiated ? BACKEND_URL + '/api/eptp/' + this.props.eptpStatus.id + "/" : BACKEND_URL + '/api/eptp/', //url for accessing the backend status api
      endpoint_eptp: BACKEND_URL + '/api/eptp/',
      updated: "",
      messageContent: "",
      messageTime: "",
      confirmationVisibility: false,
    }
  }

  handleSubmit = e => {
    this.setState({ confirmationVisibility: false })
    e.preventDefault();

    // Create a header for the request
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    headers.append('Origin', FRONTEND_URL + '/eptp');

    // Prepare request
    let url = BACKEND_URL + "/api/eptp/" + this.props.eptpStatus.id + "/";
    let conf = {
      headers: headers,
      method: 'PUT',
      body: JSON.stringify({ triggered: false, initiated: !this.props.eptpStatus.initiated }),
    };

    if (!this.props.eptpStatus.id) {
      url = BACKEND_URL + "/api/eptp/";
      conf.method = 'POST';
      conf.body = JSON.stringify({ triggered: false, initiated: true });
    }

    // Submit request
    fetch(url, conf).then(response => {
      if (response.ok) {
        return response.json();
      }
      throw new Error("There was a system error.");
    }).then(data => {
      store.dispatch(setEPTPStatus(data))
      var messageContent = data.initiated ? " ** EPTP has been  ACTIVATED **" : " ** EPTP has been DEACTIVATED **";
      var messageTime = data.initiated ? data.activatedAt : data.deactivatedAt;
      this.setState({ messageContent: messageContent, messageTime: messageTime })
    }).catch((error) => { window.alert(error.message); })

  }
  render() {
    if (this.props.userType !== "FAO") {
      return (
        <div id="PageNotFound" className="my-5">
          <h1> OOPS!</h1>
          <div className=""><h3>It looks like you don't have access to this page.</h3></div>
          <div><h3> Try one of the links in the navigation bar.</h3></div>
        </div>
      );
    }
    return (
      <div id="EPTPAccess" className="mt-5 ">
        <div className="mb-3">{this.state.messageContent}</div>
        <div className="row d-flex justify-content-center align-items-center">

          <div className="col-10 mb-5 border rounded py-5">
            <div className="col"> <h2>EPTP is currently <b>{this.props.eptpStatus.initiated ? "ACTIVATED" : "DEACTIVATED"}</b></h2></div>
            <div className="col-4">
            </div>
            <div className="col">
              <button style={this.state.confirmationVisibility ? { display: "none" } : { display: "block" }} className="btn btn-lg cotsBlue btn-block my-4" onClick={() => { this.setState({ confirmationVisibility: true }) }}>{this.props.eptpStatus.initiated ? "Deactivate" : "Activate"}</button>
            </div>
            <div className="col-4">
            </div>
            <div style={this.state.confirmationVisibility ? { display: "block" } : { display: "none" }} className="mt-3">
              <div>
                You are about to <b>{this.props.eptpStatus.initiated ? "DEACTIVATE" : "ACTIVATE"}</b> EPTP.
      </div>
              <div>
                Are you sure you want to proceed?
      </div>
              <form action="/eptp" onSubmit={this.handleSubmit}>
                <button className="btn btn-lg btn-danger btn-block mt-4" id="mobileclick" type="submit" placeholder="Submit">Confirm </button>
              </form>
              <div className="mt-3">
                <button onClick={() => { this.setState({ confirmationVisibility: false }) }} id="cancel" className="btn btn-lg cotsBlue btn-block mb-4">Cancel</button>
              </div>
            </div>
          </div>

        </div>

      </div>
    )
  }
}

export default PageWrapper('Emergency Patient Transport Plan', 'EPTP')(EPTP);
