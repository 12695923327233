import React, { Component } from 'react';
import './App.css';
import AppContainer from './containers/AppContainer'
import CacheBuster from './components/CacheBuster'

const IS_TEST_SITE = true;

class App extends Component {
  render() {
    return (
      <CacheBuster>
        {({ loading, refreshCacheAndReload, isLatestVersion }) => {
            if (loading) return null;

            return (
                <div>
                    { !isLatestVersion && (<div class="refresh-banner">Notice: There is an updated version of the RTAS web site available. <button className="ml-2" onClick={() => refreshCacheAndReload()}>Click here to refresh</button></div>) }
                    { IS_TEST_SITE && <div class="test-banner">Notice: This is the test deployment of RTAS. Changes made here will not be reflected in the live RTAS site</div> }
                    <AppContainer className={`.app-container ${!isLatestVersion || IS_TEST_SITE ? '.w-banner' : ''}`} />
                </div>
            );
        }}
      </CacheBuster>
    );
  }
}

export default App;
